import React, { useContext } from "react"
import Layout from "../../components/layout"
import NullIf from "../../services/NullIf"

import ThemeContext from "../../context/ThemeContext"
import SEO from "../../components/seo"
import { graphql } from "gatsby"
import ContentWrapper from "../../components/text-helpers/content-wrapper"
import MainSearch from "../../components/main-search/main-search"
import localize from "../../components/localize"

const Content = ({ bonuses, casinoGroup, locale }) => {

  return (
    <div>
      <MainSearch locale={locale} bonuses={bonuses} />
    </div>
  )
}

const CasinoGroupItem = ({ pageContext, location, data }) => {
  let site = useContext(ThemeContext)
  let bonuses = site.bonuses
  let { casinoGroup, locale, alternateLocaleSlugs } = pageContext

  bonuses = bonuses
    .filter(x => NullIf.isNotNullOrEmpty(x))
    .filter(x => NullIf.isNotNullOrEmpty(x.group))
    .filter(x => NullIf.isNotNullOrEmpty(x.group.name))
    .filter(x => x.group.name === casinoGroup)

  let pageData = data.sanityCasinoOwner
  if (!pageData)
    pageData = {
      seoTitle: `${casinoGroup} | Mr-Gamble.com`,
      seoMeta: `${casinoGroup} | Mr-Gamble.com`,
    }

  const seoMeta = pageData.seoMeta ||`${casinoGroup} | Mr-Gamble.com`;

  let header = pageData._rawHeaderText;
  let h1Tag = null;
  // header empty
  if (!NullIf.isNotNullOrEmpty(header)){
    h1Tag = pageData.name;
  }
  return (
    <Layout pageContext={pageContext} location={location}>
      <SEO location={location} title={pageData.seoTitle}
           alternateSlug={alternateLocaleSlugs}
           description={seoMeta} bonuses={bonuses} />
      <ContentWrapper
        title={h1Tag}
        header={header}
        footer={pageData.footerText}
      >
        <Content bonuses={bonuses} locale={locale} casinoGroup={casinoGroup} />
      </ContentWrapper>
    </Layout>
  )
}

export default localize(CasinoGroupItem)
export const pageQuery = graphql`
  query CasinoGroupQuery($casinoGroup: String!) {
    sanitySilo (_id: {eq: "15f98d4b-75f1-4ab5-9a2e-a87ce7a8383f"}){
        _id
        breadcrumbTrail {
            ...LocaleSiloTrails
        }
    }
    sanityCasinoOwner (
      name: { eq: $casinoGroup }
    ) {
      name
      seoTitle {
          ...LocaleString
      }
      _rawHeaderText(resolveReferences: {maxDepth: 10})
    }
  }
`
